.AudioControlButton {
  display: block;
  width: 32px;
  height: 32px;
  opacity: 0.5;
  cursor: pointer;
  background: transparent;
  padding: 0;
  border: 0;
  transition: opacity 0.2s ease;
}

.AudioControlButton:hover {
  opacity: 1;
}

  .AudioControlButton svg {
    width: 32px;
    height: 32px;
    fill: var(--almost-white);
  }
