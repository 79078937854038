.TrackList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  width: 100%;
}

  .TrackList-item {
    display: table-row;
  }

  .TrackList-item--active {
    font-weight: bold;
  }

  .TrackList-item .AudioControlButton svg {
    fill: var(--yellow);
  }

  .TrackList-item--active .AudioControlButton {
    opacity: 1;
  }

  .TrackList-item--active .AudioControlButton svg {
    fill: var(--red);
  }

  .TrackList-item--heading {
    font-weight: bold;
    font-family: var(--secondary-font);
    display: none;
  }

  @media (min-width: 768px) {
    .TrackList-item--heading {
      display: table-row;
    }
  }

    .TrackList-item > * {
      display: table-cell;
      vertical-align: middle;
    }

    .TrackList-item span {
      padding: var(--half-spacing-unit);
    }

    .TrackList-item span:nth-child(n+3) {
      display: none;
    }

    @media (min-width: 768px) {
      .TrackList-item span:nth-child(n+3) {
        display: table-cell;
      }
    }
