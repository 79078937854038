:root {
  --almost-black: #011627;
  --almost-white: #fdfffc;
  --blue-green: #2ec4b6;
  --red: #e71d36;
  --yellow: #ff9f1c;
  --gray: #c2c5cc;

  --primary-font: 'IBM Plex Sans', sans-serif;
  --secondary-font: 'Bungee Hairline', cursive;

  --spacing-unit: 10px;
  --half-spacing-unit: calc(var(--spacing-unit) / 2);
  --double-spacing-unit: calc(var(--spacing-unit) * 2);
  --triple-spacing-unit: calc(var(--spacing-unit) * 3);

  --header-height: 65px;
}


/** GLOBAL */

html {
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.5;
  font-family: var(--primary-font);
  color: var(--almost-black);
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
  background: var(--gray);
}


/** TYPOGRAPHY */

h1, h2 {
  font-family: var(--secondary-font);
  line-height: 1.15;
  margin: var(--spacing-unit) 0;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.5rem;
}

a {
  color: var(--red);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

p, ul {
  margin: var(--double-spacing-unit) 0;
}

li {
  margin-bottom: var(--double-spacing-unit);
}


/** IMAGES */

img {
  max-width: 100%;
}


/** HELPERS */

.constrainer {
  max-width: 1000px;
  margin: 0 auto;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}
