.App {}

  .App-body {
    position: relative;
    top: var(--header-height);
    padding: var(--double-spacing-unit);
    background: var(--almost-white);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    margin-bottom: 200px;
  }

  @media (min-width: 1000px) {
    .App-body {
      padding: var(--double-spacing-unit) var(--triple-spacing-unit);
    }
  }
