.HeaderMenu {
  color: var(--almost-white);
  text-align: center;
  margin: 0 auto;
  padding: 0 var(--double-spacing-unit) var(--double-spacing-unit);
  list-style: none;
  position: absolute;
  z-index: 10;
  top: var(--header-height);
  bottom: 0;
  overflow-y: auto;
}

  .HeaderMenu-item {
    display: inline-block;
    margin: 0;
    padding: var(--half-spacing-unit) var(--spacing-unit);
    font-weight: bold;
  }

    .HeaderMenu-item a:hover {
      color: var(--red);
    }

    .HeaderMenu-first-char {
      color: var(--red);
    }
