.HeaderButton {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: block;
  position: relative;
  top: 2px;
}

  .HeaderButton-icon {
    width: 35px;
    height: 35px;
  }

  .HeaderButton-icon {
    transition: fill 0.3s ease;
    fill: var(--almost-white);
  }

  .HeaderButton-icon:hover {
    fill: var(--blue-green);
  }

  .HeaderButton-icon--active {
    fill: var(--red);
  }

