.Header {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  background: var(--almost-black);
  font-family: var(--secondary-font);
  transition: height 0.5s ease-out;
  overflow: hidden;
}

.Header--full {
  height: 100%;
}

  .Header a {
    color: var(--almost-white);
    transition: color 0.3s ease;
    text-decoration: none;
  }

  .Header a:hover {
    color: var(--blue-green);
  }


.Header-row {
  display: grid;
  grid-template-columns: 32px auto 32px;
  grid-gap: 0 var(--spacing-unit);
  align-items: center;
  position: relative;
  z-index: 20;
  padding: var(--spacing-unit);
  box-shadow: 0 10px 5px var(--almost-black);
}

.Header-logo {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: var(--almost-white);
  font-size: 1.75rem;
  line-height: calc(var(--header-height) - var(--double-spacing-unit));
  letter-spacing: -1px;
}

@media (min-width: 1000px) {
  .Header-logo {
    font-size: 2.5rem;
  }
}
